<template>
  <b-form-group
      v-if="label"
      :label="label"
      :label-for="inputId"
  >
    <validation-provider
        #default="{errors}"
        :rules="rules"
        :name="validationName"
    >
      <b-form-input
          :id="inputId"
          :value="value"
          @input="val => $emit('input', val)"
          :state="errors.length && !value ? false : null"
          :type="type"
          :placeholder="placeholder"
      />
      <small class="text-danger" v-if="errors.length && !value && showErrorMsg">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

  <validation-provider
      v-else
      #default="{errors}"
      :rules="rules"
      :name="validationName"
  >
    <b-form-input
        :id="inputId"
        :value="value"
        @input="val => $emit('input', val)"
        :state="errors.length && !value ? false : null"
        :type="type"
        :placeholder="placeholder"
    />
    <small class="text-danger" v-if="errors.length && !value && showErrorMsg">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import {BFormGroup, BFormInput} from "bootstrap-vue";
import {required, email} from '@validations';

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    customClasses: {
      type: String,
      required: false,
      default: ""
    },
    inputId: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      default: 'required',
    },
    validationName: {
      type: String,
      required: false,
    },
    value: {
      required: false
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      required: false
    },
    showErrorMsg: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      // form validation rules
      required,
      email
    }
  },
}
</script>
